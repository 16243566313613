@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .text-shadow {
        text-shadow:
            -0.09rem 0 1.8px #000,
            0.1rem 0 1.8px #000,
            0 -0.09rem 1.8px #000,
            0 0.1rem 1.8px #000;
    }
    .trans-rights {
        background: linear-gradient(90deg,#55cdfc,#55cdfc 12%,#f7a8b8 22%,#f7a8b8 35%,#fff 45%,#fff 55%,#f7a8b8 65%,#f7a8b8 77%,#55cdfc 87%,#55cdfc) text;
        color: rgba(0, 0, 0, 0);
    }
    .to-beige {
        filter: invert(100%) brightness(70%) sepia(100%);
    }
    .shadow-light {
        box-shadow:
            1rem 1rem 3rem rgba(255, 150, 150, 0.03),
            -1rem 1rem 3rem rgba(255, 150, 150, 0.03),
            1rem -1rem 3rem rgba(255, 150, 150, 0.03),
            -1rem -1rem 3rem rgba(255, 150, 150, 0.03);
    }
}

@layer components {
    .box {
        @apply border-4 border-orange-400 rounded-md p-5 bg-amber-900/10 backdrop-blur-[2px];
    }
    .header {
        @apply text-center text-5xl text-orange-600 font-header font-bold;
    }
}
